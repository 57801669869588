













import { Component, Prop, Vue } from "vue-property-decorator";
import { TextItem } from "@/views/store/StoreResult.vue";

@Component({})
export default class TextList extends Vue {
  @Prop({ default: () => [] }) readonly items!: TextItem[];
}
