
























import { Toast, Icon, Skeleton } from "vant";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { getNow, momentFormat } from "@/utils/common.util";
import CapStoreStateStore from "@/store/modules/cap-store-state.store";
import TextList from "@/components/TextList.vue";
import InteractionLib from "@/utils/interaction.lib";

export interface TextItem {
  title: string;
  desc: string;
  color?: string;
}

@Component({
  components: { TextList, Icon, VanSkeleton: Skeleton },
})
export default class StoreResult extends Vue {
  capStoreState = getModule(CapStoreStateStore);
  order_no = this.$route.query.order_no;
  product_id = Number(this.$route.params.id);

  handleViewVoucher() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/mysupply`);
    }
    
    this.$router.push({ name: "mysupply", query: { time: String(getNow()) } });
  }

  get items(): TextItem[] {
    const {
      detail: { product_name },
      order: { create_time, order_price, pay_channel, product_type },
      deduction: { exchange_number },
    } = this.order!;
    let  word 
    if(product_type == 0){
      word = 'CDKEY';
    }else if(product_type == 1){
      word = '快递'
    }else if(product_type == 2){
      word = '金会员'
    }else if(product_type == 3){
      word = '优惠券'
    }
    const is_point = pay_channel === 0;
    const price = is_point
      ? `${exchange_number} 瓶盖`
      : `${Math.ceil(order_price) / 100} 元`;
    return [
      { title: "商品名称", desc: product_name, color: "#111111" },
      { title: "兑换时间", desc: momentFormat(create_time) },
      { title: "价格", desc: price },
      { title: "发货形式", desc: `${word}` },
    ];
  }

  get exchangeIcon() {
    switch (this.exchangeStatus) {
      case 0:
        return { name: "underway", color: "#212832" };
      case 1:
        return { name: "checked", color: "#212832" };
      case 2:
        return { name: "clear", color: "#f43041" };
      case 3:
        return { name: "warning", color: "#ff6a00" };
    }
    
    return { name: "warning", color: "#ff6a00" };
  }

  get exchangeText() {
    switch (this.exchangeStatus) {
      case 0:
        return "排队中";
      case 1:
        return "兑换成功";
      case 2:
        return this.order?.refund?.content;
      case 3:
        return "已取消";
    }
    return "";
  }

  get exchangeStatus() {
    const index = this.order?.status.index || -1;
    if ([0, 1].includes(index)) {
      return 0;
    }
    if (index === 2) {
      return 1;
    }
    if ([3, 4].includes(index)) {
      return 2;
    }
    return 3;
  }

  get skeleton() {
    return this.capStoreState.orderSkeleton;
  }

  get order() {
    return this.capStoreState.order;
  }

  async created() {
    if (!this.order || this.order.order.order_no !== this.order_no) {
      await this.init();
    }
  }

  async init() {
    await this.capStoreState.getProductBuyRecord({
      id: this.product_id,
      order_no: this.order_no as string,
    });
    if (this.exchangeStatus === 0) {
      const toast = Toast.loading("排队中...");
      setTimeout(() => {
        toast.close();
        this.init();
      }, 3000);
    }
  }
}
